@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Minecraft";
  src: url(assets/Minecraft.woff) format("woff");
}

* {
  font-family: "Minecraft", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

body {
  user-select: none;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  pointer-events: none;
}

.mc-grid {
  width: 54px;
  height: 54px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-align: center !important;
  background-color: #8b8b8b;
  border: 3px solid;
  border-top-color: #373737;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: #373737;
  vertical-align: middle;

  &::before {
    bottom: -3px;
    left: -3px;
  }

  &::after {
    top: -3px;
    right: -3px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: #8b8b8b;
    height: 3px;
    width: 3px;
    pointer-events: none;
  }
}

.mc-tooltip {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #100010;
  background-color: rgba(16, 0, 16, 0.94);
  color: #fff;
  padding: 0.375em;
  font-family: Minecraft, sans-serif;
  font-size: 16px;
  word-spacing: 4px;
  white-space: nowrap;
  line-height: 1.25em;
  text-shadow: 0.125em 0.125em 0 #3f3f3f;
  margin: 0.125em 0.25em;
  pointer-events: none;
  z-index: 99;
}

.mc-tooltip:before {
  content: "";
  position: absolute;
  top: 0.125em;
  right: -0.125em;
  bottom: 0.125em;
  left: -0.125em;
  border: 0.125em solid #100010;
  border-style: none solid;
  border-color: rgba(16, 0, 16, 0.94);
}

.mc-tooltip:after {
  content: "";
  position: absolute;
  top: 0.125em;
  right: 0;
  bottom: 0.125em;
  left: 0;
  border: 2px solid #2d0a63;
  border-image: linear-gradient(rgba(80, 0, 255, 0.31), rgba(40, 0, 127, 0.31))
    1;
}

.pixelated {
  image-rendering: pixelated;
}

.mc-button {
  display: inline-block;
  position: relative;
  padding: 16px 40px 24px 40px;
  text-align: center;
  color: #1c1d1f;
  background-color: #d1d2d4;
  border: 4px solid #1f1f1f;
  border-bottom-width: 4px;
  cursor: pointer;
  font-size: 20px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 8px;
    left: 0;
    border: 4px solid #dfe0e2;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    background-color: #58585a;
  }
}

.mc-button-icon {
  background-color: #3c8528;
  padding: 16px 20px 24px 20px;

  &::after {
    border-color: #669c53;
  }

  &::before {
    background-color: #14510b;
  }
}

.mc-button:active {
  padding: 16px 40px;
  background-color: #8d8d90;
  margin-top: 8px;
  /* border-color: #1f1f1f */

  &::after {
    bottom: 0px;
    border-color: #97979a;
  }

  &::before {
    height: 0px;
  }
}

.mc-button-icon:active {
  background-color: #3c8528;
  padding: 16px 20px;
  margin-top: 8px;

  &::after {
    border-color: #669c53;
  }

  &::before {
    background-color: #14510b;
  }
}
